var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"how"},[_c('Mytitle',{attrs:{"data":{
        h: '拓幻是一家怎样的公司？',
      }}}),_c('p',[_vm._v(" 合肥拓幻智能科技致⼒于⼈⼯智能（AI）和增强现实（AR），以⼈脸识别和追踪技术为切⼊点，打造智能AR的商业模式。 ")]),_c('p',[_vm._v(" 我们为⼈脸消费级产品提供技术解决⽅案，通过⼈脸检测、识别及追踪技术，图形图像处理，硬件加速，在移动端实时渲染2D、3D贴纸及⼆次元卡通形象，并实现滤镜、美颜、美妆、 背景抠图、换脸等功能。这项业务主要为直播、短视频、连⻨等平台服务。 ")]),_c('p',[_vm._v(" 团队亦提供定制开发版全⽅位技术解决⽅案，依据不同平台（包括Android、IOS、Windows Phone、Web、服务器端等）开发具备⼈脸检测、⼈脸关键点定位、⼈脸追踪、表情判定、 ⼿势识别、活体检测等⾼壁垒技术的⼈⼯智能应⽤和拥有多维度图像渲染技术、图形处理、硬件加速等⾼精尖技术的⼈机交互软硬件应⽤。 ")]),_vm._m(1),_vm._m(2)],1),_c('div',{staticClass:"culture"},[_c('Mytitle',{attrs:{"data":{
        h: '文化，让我们与众不同',
      }}}),_vm._m(3)],1),_c('div',{staticClass:"advantage"},[_c('Mytitle',{attrs:{"data":{
        h: '我们的优势',
      }}}),_vm._m(4)],1),_c('Try')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home_top"},[_c('div',{staticClass:"top_text"},[_c('h1',{staticClass:"top_h"},[_vm._v("拓幻科技，让幻想不止于此")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/home/company/拓幻科技门头.png"),"alt":""}})]),_c('div',[_c('img',{attrs:{"src":require("@/assets/home/company/4.jpg"),"width":"366","alt":""}})]),_c('div',[_c('img',{attrs:{"src":require("@/assets/home/company/6.jpg"),"width":"366","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/home/company/2.jpg"),"width":"366","alt":""}})]),_c('div',[_c('img',{attrs:{"src":require("@/assets/home/company/3.jpg"),"width":"366","alt":""}})]),_c('div',[_c('img',{attrs:{"src":require("@/assets/home/company/5.jpg"),"width":"366","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex_box"},[_c('div',{staticClass:"img_div"},[_c('img',{attrs:{"src":require("@/assets/home/company/企业介绍_价值观.png"),"alt":""}}),_c('div',{staticClass:"text"},[_c('span',[_c('h3',[_vm._v("价值观")]),_vm._v(" 长于专业、信任包容、共赢未来 ")])])]),_c('div',{staticClass:"img_div"},[_c('img',{attrs:{"src":require("@/assets/home/company/企业介绍_愿景.png"),"alt":""}}),_c('div',{staticClass:"text"},[_c('span',[_c('h3',[_vm._v("愿景")]),_vm._v(" 让幻想不止于此 ")])])]),_c('div',{staticClass:"img_div"},[_c('img',{attrs:{"src":require("@/assets/home/company/企业介绍_使命.png"),"alt":""}}),_c('div',{staticClass:"text"},[_c('span',[_c('h3',[_vm._v("使命")]),_vm._v(" 成为新一代科技型互联网企业领航者 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"adv_div"},[_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/home/company/icon_advantage_tuandui@2x.png"),"alt":"精英团队"}}),_c('h3',[_vm._v("精英团队")]),_c('p',[_vm._v("团队成员来美国留学博士硕士;国内985高校硕士学士")])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/home/company/icon_advantage_product@2x.png"),"alt":"成熟产品"}}),_c('h3',[_vm._v("成熟产品")]),_c('p',[_vm._v("近五年的技术研发积累和测试沉淀，成熟、安全、高效")])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/home/company/icon_advantage_service@2x.png"),"alt":"稳定服务"}}),_c('h3',[_vm._v("稳定服务")]),_c('p',[_vm._v("同时使用亚马逊AWS和阿里云服务，为稳定运行提供坚实基础")])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/home/company/icon_advantage_support@2x.png"),"alt":"技术支持"}}),_c('h3',[_vm._v("技术支持")]),_c('p',[_vm._v(" 专业的售前客服、售后技术团队提供一对一技术支持，即时解决技术问题 ")])])])
}]

export { render, staticRenderFns }