<template>
  <div>
    <!-- 大图部分 -->
    <div class="home_top">
      <div class="top_text">
        <h1 class="top_h">拓幻科技，让幻想不止于此</h1>
      </div>
    </div>
    <!-- 拓幻 -->
    <div class="how">
      <Mytitle
        :data="{
          h: '拓幻是一家怎样的公司？',
        }"
      ></Mytitle>
      <p>
        合肥拓幻智能科技致⼒于⼈⼯智能（AI）和增强现实（AR），以⼈脸识别和追踪技术为切⼊点，打造智能AR的商业模式。
      </p>
      <p>
        我们为⼈脸消费级产品提供技术解决⽅案，通过⼈脸检测、识别及追踪技术，图形图像处理，硬件加速，在移动端实时渲染2D、3D贴纸及⼆次元卡通形象，并实现滤镜、美颜、美妆、
        背景抠图、换脸等功能。这项业务主要为直播、短视频、连⻨等平台服务。
      </p>
      <p>
        团队亦提供定制开发版全⽅位技术解决⽅案，依据不同平台（包括Android、IOS、Windows
        Phone、Web、服务器端等）开发具备⼈脸检测、⼈脸关键点定位、⼈脸追踪、表情判定、
        ⼿势识别、活体检测等⾼壁垒技术的⼈⼯智能应⽤和拥有多维度图像渲染技术、图形处理、硬件加速等⾼精尖技术的⼈机交互软硬件应⽤。
      </p>
      <div class="left">
        <div>
          <img src="@/assets/home/company/拓幻科技门头.png" alt="" />
        </div>
        <div>
          <img src="@/assets/home/company/4.jpg" width="366" alt="" />
        </div>
        <div>
          <img src="@/assets/home/company/6.jpg" width="366" alt="" />
        </div>
      </div>
      <div class="right">
        <div>
          <img src="@/assets/home/company/2.jpg" width="366" alt="" />
        </div>
        <div>
          <img src="@/assets/home/company/3.jpg" width="366" alt="" />
        </div>
        <div>
          <img src="@/assets/home/company/5.jpg" width="366" alt="" />
        </div>
      </div>
    </div>
    <!-- 文化 -->
    <div class="culture">
      <Mytitle
        :data="{
          h: '文化，让我们与众不同',
        }"
      ></Mytitle>
      <div class="flex_box">
        <div class="img_div">
          <img src="@/assets/home/company/企业介绍_价值观.png" alt="" />
          <div class="text">
            <span>
              <h3>价值观</h3>
              长于专业、信任包容、共赢未来
            </span>
          </div>
        </div>
        <div class="img_div">
          <img src="@/assets/home/company/企业介绍_愿景.png" alt="" />
          <div class="text">
            <span>
              <h3>愿景</h3>
              让幻想不止于此
            </span>
          </div>
        </div>
        <div class="img_div">
          <img src="@/assets/home/company/企业介绍_使命.png" alt="" />
          <div class="text">
            <span>
              <h3>使命</h3>
              成为新一代科技型互联网企业领航者
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- 优势 -->
    <div class="advantage">
      <Mytitle
        :data="{
          h: '我们的优势',
        }"
      ></Mytitle>
      <div class="adv_div">
        <div class="item">
          <img
            src="@/assets/home/company/icon_advantage_tuandui@2x.png"
            alt="精英团队"
          />
          <h3>精英团队</h3>
          <p>团队成员来美国留学博士硕士;国内985高校硕士学士</p>
        </div>
        <div class="item">
          <img
            src="@/assets/home/company/icon_advantage_product@2x.png"
            alt="成熟产品"
          />
          <h3>成熟产品</h3>
          <p>近五年的技术研发积累和测试沉淀，成熟、安全、高效</p>
        </div>
        <div class="item">
          <img
            src="@/assets/home/company/icon_advantage_service@2x.png"
            alt="稳定服务"
          />
          <h3>稳定服务</h3>
          <p>同时使用亚马逊AWS和阿里云服务，为稳定运行提供坚实基础</p>
        </div>
        <div class="item">
          <img
            src="@/assets/home/company/icon_advantage_support@2x.png"
            alt="技术支持"
          />
          <h3>技术支持</h3>
          <p>
            专业的售前客服、售后技术团队提供一对一技术支持，即时解决技术问题
          </p>
        </div>
      </div>
    </div>
    <!-- 试用 -->
    <Try></Try>
  </div>
</template>
<script>
import Try from "@/components/Try/index"; //试用
import Mytitle from "@/components/Mytitle/index"; //标题

export default {
  components: {
    Try,
    Mytitle,
  },
};
</script>
<style lang="scss" scoped>
$width: 79.1%;
// 大图
.home_top {
  width: 100%;
  height: 500px;
  line-height: 400px;
  text-align: center;
  color: #fff;
  background: url("~@/assets/home/company/企业介绍首图.png") no-repeat center;
  background-size: 100%;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  .top_h {
    font-size: 44px;
    font-weight: 500;
  }
}
// 拓幻
.how {
  /* width: $width; */
  width: 1140px;
  margin: auto;
  p {
    /* width: 100%; */
    width: 1140px;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 24px;
    margin: 20px 0;
  }
  .left {
    display: inline-block;
    /* width: 65.9%; */
    width: 752px;
    margin-right: 20px;
    vertical-align: top;
    div:first-child {
      width: 100%;
      height: 420px;
      margin-bottom: 30px;
      background: #eeeeee;
      overflow: hidden;
    }
    div:nth-child(2),
    div:nth-child(3) {
      width: 48.6%;
      height: 200px;
      display: inline-block;
      background: #eeeeee;
      overflow: hidden;
    }
    div:nth-child(2) {
      margin-right: 20px;
      overflow: hidden;
    }
  }
  .right {
    display: inline-block;
    /* width: 32.1%; */
    width: 366px;
    vertical-align: top;
    div {
      width: 100%;
      height: 200px;
      margin-bottom: 23px;
      background: #eeeeee;
      overflow: hidden;
    }
    div:last-child {
      margin: 0;
    }
  }
}
// 文化
.culture {
  .flex_box {
    display: flex;
  }
  .img_div {
    width: 33.33%;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    box-shadow: inset 0 0 0 1000px rgba($color: #1d1d1d, $alpha: 0.3);
    img {
      display: block;
      width: 100%;
      transition: all 1s;
      position: relative;
      z-index: -1;
    }
    .text {
      width: 100%;
      height: 99.3%;
      position: absolute;
      top: 0;
      h3 {
        font-size: 20px;
        font-weight: 500;
        color: #ffffff;
        line-height: 38px;
        margin: 518px 0 31px 0;
      }
      span {
        display: block;
        position: absolute;
        left: 61px;
        bottom: 60px;
        transition: bottom 1s;
      }
    }
  }
  .img_div:hover {
    img {
      transform: scale(1.2);
    }
    .text {
      background: rgba($color: #1d1d1d, $alpha: 0.7);
      height: 100%;
    }
    span {
      bottom: 120px;
    }
  }
}
// 优势
.advantage {
  height: 369px;
  text-align: center;
  .adv_div {
    width: $width;
    margin: auto;
    display: flex;
    justify-content: space-between;
    .item {
      width: 213px;
      img {
        width: 60px;
        height: 60px;
      }
      h3 {
        height: 45px;
        font-size: 16px;
        font-weight: 400;
        color: #222222;
        line-height: 40px;
      }
      p {
        width: 213px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
      }
    }
  }
}
</style>